import React from "react";
import RitiroComponent from "../components/RitiroComponent";

const Ritiro = () => {
  return (
    <div className="container-center f-col">
      <RitiroComponent />
    </div>
  );
};

export default Ritiro;
