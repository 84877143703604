import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const SelectComponent = ({
  name,
  options,
  handleChange,
  infoDisambled,
  label,
  value,
  styled,
}) => {
  return (
    <div className="container-center f-col m-1">
      <FormControl size="small" className={styled ? "inputRed" : ""}>
        <InputLabel id="demo-select-small-label">{label}</InputLabel>
        <Select
          className="uniformInput "
          id="demo-select-small"
          name={name}
          labelId="demo-select-small-label"
          value={value}
          label={label}
          required
          disabled={infoDisambled}
          onChange={handleChange}
        >
          {options
            ? options.map((option, i) => (
                <MenuItem value={option} key={i}>
                  {option}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectComponent;
