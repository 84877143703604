import React from "react";
import SelectComponent from "../components/SelectComponent";
import { tipiDelVeicolo, statoDelVeicolo } from "../utility/param";
import { useGlobalContext } from "../context";

const StatoTipo = () => {
  const { handleChange, form, veicolo, catalog } = useGlobalContext();
  const { tipo_veicolo, stato_veicolo } = form;

  return (
    <div className="container-center  statoTipo">
      <SelectComponent
        name={"tipo_veicolo"}
        options={tipiDelVeicolo}
        handleChange={handleChange}
        infoDisambled={catalog || veicolo}
        label={"Tipo del veicolo"}
        value={tipo_veicolo}
        styled={true}
      />

      <div className="container-center m-2">
        <SelectComponent
          name={"stato_veicolo"}
          options={statoDelVeicolo}
          handleChange={handleChange}
          infoDisambled={false}
          label={"Stato del veicolo"}
          value={stato_veicolo}
          styled={true}
        />
      </div>
    </div>
  );
};

export default StatoTipo;
