import ApiServices from "../api";
import { arrYears, trasformaChiaviInMinuscolo } from "../utilityFunction";

export default class VeicoliService {
  #Api = new ApiServices();

  async marche(setter) {
    const { items } = await this.#Api.get(this.#Api.urls.API_MARCA);
    const arr =
      items && items.length > 0
        ? items.sort((a, b) => a.MARCA.localeCompare(b.MARCA))
        : [];
    setter(arr);
  }

  async modelli(idmar, setter) {
    setter([]);
    if (idmar) {
      const URL = `${this.#Api.urls.API_MODELLO}${idmar}`;
      const { items } = await this.#Api.get(URL);
      //? filter serve ad eliminare modelli uguali, altrimenti select mi da avisso e sort per ordinare per nome
      const arr =
        items && items.length > 0
          ? items
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.MODELLO === item.MODELLO)
              )
              .sort((a, b) => a.MODELLO.localeCompare(b.MODELLO))
          : [];

      setter(arr);
    }
  }

  async versioni(idmar, idmod, setter) {
    setter([]);
    if (idmar && idmod) {
      const URL = `${this.#Api.urls.API_VERSIONE}${idmar}&IDMOD=${idmod}`;
      const { items } = await this.#Api.get(URL);
      //? filter serve ad eliminare versioni uguali, altrimenti select mi da avisso e sort per ordinare per nome
      const arr =
        items && items.length > 0
          ? items
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.VERSIONE === item.VERSIONE)
              )
              .sort((a, b) => a.VERSIONE.localeCompare(b.VERSIONE))
          : [];
      setter(arr);
    }
  }

  async datiVeicolo(obj, setter, setterForm, catalog) {
    setter([]);
    catalog(false);
    const { IDMAR, IDMOD, IDVER } = obj;
    if (IDMAR && IDMOD && IDVER) {
      const URL = `${
        this.#Api.urls.API_CATALOGO_AUTO
      }${IDMAR}&IDMOD=${IDMOD}&IDVER=${IDVER}`;
      const response = await this.#Api.get(URL);
      if (response?.items?.length > 0) {
        const veicolo = response.items[0];
        const { TipoVeicolo, INICO, FINCO, CODMO } = veicolo;

        const tipo =
          TipoVeicolo === "autovettura" ? "autoveicolo" : TipoVeicolo;
        const updatedForm = {
          ...obj,
          tipo_veicolo: tipo,
          cod_motore: CODMO,
          //veicolo,
        };
        setter(arrYears(INICO, FINCO)); // crea array anni in base al inizio e fine commercio
        setterForm(updatedForm);
        catalog(true);
      } else
        console.log("La risposta contiene dati, ma l'array items è vuoto.");
    }
  }

  /*  async findTarga(targa, form) {
    const response = await this.#Api.get(
      `${this.#Api.urls.search_targa}${targa}?api_key=${
        this.#Api.license_api_key
      }`
    );

    if (!response?.success) {
      return this.#Api.err("Errore durante ricerca per targa");
    }

    const {
      dsmar,
      dsmod,
      dsver,
      tipoveicolo,
      dataimmita,
      idmar,
      idmod,
      idver,
      codmotore,
      telaio,
    } = trasformaChiaviInMinuscolo(response.data[0]);

    //creazione di form temp e inserimento dati in form
    const tipo = tipoveicolo === "Automobile" ? "autoveicolo" : tipoveicolo;
        const updatedForm = {
      ...form,
      marca: dsmar,
      modello: dsmod,
      tipo_veicolo: tipo,
      versione: dsver,
      anno: dataimmita.slice(-4),
      veicolo: response.data[0],
      cod_motore: codmotore,
      IDMAR: idmar,
      IDMOD: idmod,
      IDVER: idver,
      telaio,
    };
    return response;
  } */
}
