import React from "react";
import { useGlobalContext } from "../context";

const stringLabel =
  "Vuoi consegnare il veicolo direttamente presso una delle nostre sedi?";

const CheckConsegna = () => {
  const { checkedPrenot, setCheckedPrenot } = useGlobalContext();

  return (
    <label className="checkbox m-2 has-text-centered px-4">
      <input
        className="mr-1 checkConsegna"
        type="checkbox"
        name="consegna"
        checked={checkedPrenot}
        onChange={() => {
          setCheckedPrenot(!checkedPrenot);
        }}
      />
      {stringLabel}
    </label>
  );
};

export default CheckConsegna;
