import GeoNames from "geonames.js";

const geonames = GeoNames({
  username: "software3",
  lan: "en",
  encoding: "JSON",
});

/* GET REGIONI e PROVINCE */
export const getStatesOfCountry = async (param, dest) => {
  try {
    const response = await geonames.children({ geonameId: param });
    const data = response.geonames.map((option) => ({
      name: option.toponymName,
      geonameId: option.geonameId,
    }));

    dest(data);
  } catch (error) {
    console.log(error);
  }
};

/* GET CITTA */
export const getCitiesOfRegion = async (region, dest) => {
  try {
    const response = await geonames.children({ geonameId: region });
    const data = response.geonames.map((option) => ({
      name: option.toponymName,
    }));
    dest(data);
  } catch (error) {
    console.log(error);
  }
};

/* GET CAP */
export const searchCityPostalCode = async (cityName) => {
  if (cityName) {
    try {
      const result = await geonames.postalCodeLookup({
        placename: cityName,
        maxRows: 1,
        country: "IT", // Codice ISO del paese (Italia)
      });

      if (result.postalcodes.length > 0) {
        const postalCode = result.postalcodes[0].postalcode;
        return postalCode;
      } else {
        return 0;
      }
    } catch (error) {
      console.error("Si è verificato un errore:", error.message);
    }
  } else {
    return "";
  }
};
