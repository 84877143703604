import React, { useCallback } from "react";
import { TextField } from "@mui/material";
import Captcha from "./Captcha";
import ErrorMessage from "./ErrorMessage";
import { alertsObj, exampleAlert, simpleAlert } from "../utility/SweetAlert";
import { useGlobalContext } from "../context";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ApiServices from "../utility/api";
import Button from "./Button";
import { recaptchaVerification } from "../utility/services/recaptchaVerification";

const apiServices = new ApiServices();

const SearchTarga = () => {
  const {
    findTarga,
    handleChange,
    formError,
    checkedTarga,
    form,
    setFormError,
  } = useGlobalContext();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = async (targa, formData) => {
    try {
      //controllo value targa
      const regex = /^[a-zA-Z0-9]+$/;

      if (!regex.test(targa) || targa.length < 5) {
        const temp = { ...formError, targa: "targa non valida!" };
        setFormError(temp);
        return;
      }
      if (
        formData?.veicolo &&
        formData?.veicolo.targa.toLowerCase() === targa.toLowerCase()
      ) {
        return;
      }

      if (!executeRecaptcha) {
        return;
      }

      const token = await executeRecaptcha("search_targa");
      if (token) {
        const response = await recaptchaVerification(token);
        if (!response) {
          simpleAlert(alertsObj.recapcha_error);
        }

        findTarga(targa, formData);
      } else {
        throw new Error("Errore durante la generazione del token reCAPTCHA");
      }
    } catch (error) {
      console.error("Si è verificato un errore:", error);
      simpleAlert({
        ...alertsObj.recapcha_error,
        text: "Si è verificato un errore durante la verifica del reCAPTCHA",
      });
    }

    // Do whatever you want with the token
  };

  return (
    <div className="container-flex f-col my-2">
      <div className="container-center f-col">
        <div className="container-center datiPersonali gap-5 autocompleateContainer">
          <TextField
            inputProps={{
              style: {
                textTransform: "uppercase",
              },
            }}
            className="searchTarga inputRed"
            id="targa"
            name="targa"
            size="small"
            label="Targa"
            value={form.targa}
            onChange={handleChange}
          />
          <Button
            class_name={"btnCerca"} //btnCerca m-2
            type="button"
            description="Ricerca veicolo per targa"
            disabled={form?.targa?.length < 3}
            title={"Cerca"}
            /*  handleClick={() => findTarga(form.targa, form)} */
            handleClick={() => handleReCaptchaVerify(form.targa, form)}
          />
        </div>
        {formError && <ErrorMessage message={formError.targa} />}
      </div>
      {/*   <Captcha /> */}
    </div>
  );
};

export default SearchTarga;
