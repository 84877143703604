import { createContext, useContext, useEffect, useRef, useState } from "react";
import { formObject } from "./utility/formParam";
import {
  arrImages,
  currentDate,
  currentHour,
  dataNum,
  selectedData,
  updateConsegna,
  elencoOra,
  trasformaChiaviInMinuscolo,
  dataOra,
} from "./utility/utilityFunction";
import { alertsObj, exampleAlert, simpleAlert } from "./utility/SweetAlert";
import { searchCityPostalCode } from "./utility/geoFunction";
import ApiServices from "./utility/api";
import VeicoliService from "./utility/services/marcheModelliVersioni";
import RichiestaRottamazione from "./utility/services/postRott_veicoli";
import EmailService from "./utility/services/emailService";
import { f_error, file_size } from "./utility/param";

const AppContext = createContext();
const apiServices = new ApiServices();
const veicoliService = new VeicoliService();
const emailService = new EmailService();
const richiestaRottamazione = new RichiestaRottamazione();

const AppProvider = ({ children }) => {
  /* dati della rottamazione */
  const [form, setForm] = useState({ ...formObject });
  /*array dei  orari */
  const [listOrario, setListOrario] = useState([]);
  /* controllo se targa inserita*/
  const [veicolo, setVeicolo] = useState(false);
  /* controllo se captcha stato selezionato*/
  const [checkedTarga, setCheckedTarga] = useState(false);
  /* controllo se stata scelta opzione prenota appuntamento */
  const [checkedPrenot, setCheckedPrenot] = useState(false);
  /* forma per controllo ristrizioni */
  const [formError, setFormError] = useState({});
  /* privacy */
  const [privacy, setPrivacy] = useState(false);
  /* array Marche  */
  const [marcaArr, setMarcaArr] = useState([]);
  /* array Modelli */
  const [modelArr, setModelArr] = useState([]);
  /* array Versione */
  const [versionArr, setVersionArr] = useState([]);
  /* array anno imatricolazione */
  const [yearArr, setYearArr] = useState([]);
  /* control catalogo */
  const [catalog, setCatalog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  /*  const captchaRef = useRef(null); */
  const idMarca = marcaArr.find((item) => item.DSMAR === form.marca);
  const idModel = modelArr.find((item) => item.DSMOD === form.modello);
  const idVersion = versionArr.find((item) => item.DSVER === form.versione);

  //chiami marche con primo render
  useEffect(() => {
    veicoliService.marche(setMarcaArr);

    /* marcaData(); */
  }, []);

  /* anula ritiro o appuntamento in base al check prenotazione */
  useEffect(() => {
    updateConsegna(checkedPrenot, form, setForm);
  }, [checkedPrenot]);

  /* se modifica form chiama validateForm per aggiornare errori e controlare stato del veicolo */
  useEffect(() => {
    validateForm();
    if (form.stato_veicolo === "completo marciante") {
      form.gomme = "true";
    }
  }, [form]);

  /* --------------- FETCHS------------------ */
  /* RICERCA PER TARGA*/
  const findTarga = async (paramTarga, formData) => {
    try {
      const response = await fetch(
        `${apiServices.urls.search_targa}${paramTarga}?api_key=${process.env.REACT_APP_FIND_TARGA_KEY}`
        //  `https://www.autodemolizionepollini.it/api/v1/ext/license_plate/${paramTarga}?api_key=U9zaUR7dmwUqhNkM`
      );

      const data = await response.json();
      if (data.success) {
        const veicolo = trasformaChiaviInMinuscolo(data.data[0]);
        const {
          dsmar,
          dsmod,
          dsver,
          tipoveicolo,
          dataimmita,
          idmar,
          idmod,
          idver,
          codmotore,
          telaio,
        } = veicolo;
        /*creazione di form temp e inserimento dati in form */
        const tipo = tipoveicolo === "Automobile" ? "autoveicolo" : tipoveicolo;
        const updatedForm = {
          ...formData,
          marca: dsmar,
          modello: dsmod,
          tipo_veicolo: tipo,
          versione: dsver,
          anno: dataimmita.slice(-4),
          veicolo: data.data[0],
          cod_motore: codmotore,
          IDMAR: idmar,
          IDMOD: idmod,
          IDVER: idver,
          telaio,
        };
        setForm(updatedForm);
        setVeicolo(true);
      } else {
        const temp = { ...formError, targa: "Targa non trovata!" };
        setFormError(temp);
        throw new Error("targa non trovata!");
      }
    } catch (error) {
      console.log(error);
    }

    /*   captchaRef.current.reset(); */
    setCheckedTarga(false);
  };

  /* INVIO RICHIESTA ROTTAMAZIONE */
  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = validateForm();

    if (!isValid.isValid) {
      simpleAlert({ ...alertsObj.validation_dati, text: isValid.error });

      return;
    }

    setIsLoading(true);
    let date = dataOra();
    const res = await richiestaRottamazione.vehicleDataManagement(
      form,
      checkedPrenot,
      date
    );

    simpleAlert(res.success ? alertsObj.rot_inviata : res.err);
    if (res?.success) {
      //invio file
      const formDataFiles = emailService.formDataFiles(
        form,
        res.data.lastInsertId,
        date
      );
      await apiServices.postFormData(
        apiServices.urls.upload_files,
        formDataFiles
      );
      // mando email
      const formDataToSend = emailService.formDataToSend(
        form,
        res.data.lastInsertId
      );
      const rEmail = await apiServices.postFormData(
        apiServices.urls.send_email,
        formDataToSend
      );
      resetAllParams();
    }

    setIsLoading(false);
  };

  /* ----------FUNCTIONALITY------------------*/

  /* INPUT MMV */
  const autocompleateHandleChange = (event, param, arr) => {
    let temp = { ...form };
    let value = event.target.value;
    if (!value && event.target.textContent) {
      value = event.target.textContent;
    }

    let id = null;

    if (arr?.length > 0 && param !== "anno") {
      id = arr.find(
        (item) =>
          item[param.toUpperCase()].toLowerCase() === value.toLowerCase().trim()
      );
    }
    // in base quale input viene modificato input sottostanti si azzerano
    switch (param) {
      case "marca":
        temp[param] = value;
        temp.modello = "";
        temp.versione = "";
        temp.anno = "";
        temp.cod_motore = "";
        temp.IDMAR = id?.IDMAR ? id.IDMAR : "";
        temp.IDMOD = "";
        temp.IDVER = "";
        temp.tipo_veicolo = "autoveicolo";

        setCatalog(false);
        veicoliService.modelli(temp.IDMAR, setModelArr);
        break;
      case "modello":
        temp[param] = value;
        temp.versione = "";
        temp.anno = "";
        temp.cod_motore = "";
        temp.IDMOD = id?.IDMOD ? id.IDMOD : "";
        temp.IDVER = "";
        temp.tipo_veicolo = "autoveicolo";

        setCatalog(false);
        veicoliService.versioni(temp.IDMAR, temp.IDMOD, setVersionArr);
        break;
      case "versione":
        temp[param] = value;
        temp.anno = "";
        temp.cod_motore = "";
        temp.IDVER = id?.IDVER ? id.IDVER : "";
        temp.tipo_veicolo = "autoveicolo";

        setCatalog(false);
        form.versione = value;
        veicoliService.datiVeicolo(temp, setYearArr, setForm, setCatalog);
        break;

      default:
        temp[param] = value.trim();

        break;
    }
    setForm(temp);
  };

  /* HANDLE CHANGE UNIVERSALE*/
  // scrive value in key in base a event name e value
  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedForm = { ...form, [name]: value }; //.trim()
    setForm(updatedForm);
  };

  /* FORM UPDATE HELPER */
  //per inserire value in oggetti al interno del form
  const formUpdate = (param, dest) => {
    const { name, value } = param.target;
    const upladetData = { ...form[dest], [name]: value }; // .trim()
    const updatedForm = { ...form, [dest]: upladetData };
    setForm(updatedForm);
  };

  /* INFO RITIRO */
  const handleRitiro = (event) => {
    formUpdate(event, "ritiro");
  };

  /* UPLOAD FOTO AND DOCUMENT */
  const handleUpload = (doc, event) => {
    /*  in base dove viene inserito file lo inserisco in oggetto del form addato */
    const name = event.target.name;
    const value = event.target.files[0];
    const maxSize = file_size * 1024 * 1024;
    if (value.size > maxSize) {
      setFormError((prevErrors) => ({ ...prevErrors, [name]: 1 }));
    } else {
      setFormError((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
    const uploadDocuments = { ...form[doc], [name]: value };
    const updatedForm = { ...form, [doc]: uploadDocuments };
    setForm(updatedForm);
  };

  /* function ressetare form */
  /*   const resetForm = (title, text, icon) => {
    exampleAlert(title, text, icon);
    setForm({ ...formObject });
    setVeicolo(false);
    setCheckedPrenot(false);
    setCheckedTarga(false);
    setPrivacy(false);
    setCatalog(false);
  }; */
  const resetAllParams = () => {
    setForm({ ...formObject });
    setListOrario([]);
    setVeicolo(false);
    setCheckedTarga(false);
    setCheckedPrenot(false);
    setFormError({});
    setModelArr([]);
    setVersionArr([]);
    setYearArr([]);
    setCatalog(false);
    setPrivacy(false);
  };

  /* CANCELLA DATI */
  const cancelData = () => {
    exampleAlert(
      "Attenzione",
      "",
      "warning",
      `<p>Cosi perderai tutti dati inseriti!<br>Proseguire?</p>`,
      false,
      false,
      false,
      true,
      "Si"
    ).then((result) => {
      // richiede conferma prima di cancellare dati
      if (result.isConfirmed) {
        resetAllParams();
        simpleAlert(alertsObj.dati_cancelati); /* .then((result) => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }); */
      }
    });
  };

  /* SEDE APPUNTAMENTO */
  const handleChangeSede = (event) => {
    /* in base a sede e giorno inserito creo una array di orrari e azzero inserimento precedente*/
    setListOrario(elencoOra(form.appuntamento.data, event.target.value));
    form.appuntamento.orario = "";
    form.appuntamento.data = "";
    formUpdate(event, "appuntamento");
  };

  /* DATA APPUNTAMENTO */
  const handleChangeData = (event) => {
    /* in base a sede e giorno inserito creo una array di orrari e azzero inserimento precedente*/
    setListOrario(elencoOra(event.target.value, form.appuntamento.sede));
    form.appuntamento.orario = "";
    formUpdate(event, "appuntamento");
  };

  /* ORA APPUNTAMENTO */
  const handleChangeOra = (event) => {
    formUpdate(event, "appuntamento");
  };

  /* INPUT APUNTAMENTO */
  const autocompleateHandlleApuntamento = async (event, param) => {
    /* in base al param inserito si azzerano campi sottostanti di ritiro con inserimento nuovi valori */
    const value = event.target.textContent;
    if (param === "regione") {
      form.ritiro.provincia = "";
      form.ritiro.citta = "";
      form.ritiro.cap = "";
    }
    if (param === "provincia") {
      form.ritiro.citta = "";
      form.ritiro.cap = "";
    }
    if (param === "citta") {
      form.ritiro.cap = await searchCityPostalCode(value);
    }

    const uploadRitiro = { ...form.ritiro, [param]: value };
    const updatedForm = { ...form, ritiro: uploadRitiro };
    setForm(updatedForm);
  };

  /* RICERCA ERRORI  --------------------*/
  const validateForm = () => {
    const {
      documenti,
      cli_number,
      email,
      appuntamento,
      fotoValutazione,
      ritiro,
    } = form;
    const regexEmail = /^\w+([.,_,-]?\w+)*@\w+([.,_,-]?\w+)*(\.\w{2,3})+$/;

    let err = {};
    /* qui creo oggetto errori per controlare campi */
    /* ANNO */
    /*   if (annoImmatricolazione.length !== 4 || /\D/.test(annoImmatricolazione)) {
      err.annoImmatricolazione = "Dati non validi";
    } */
    //recapiti
    /*    if (email.length) {
      if (!regexEmail.test(email)) {
        err.email = "Email non valida!";
      }
    } */

    /*  if (
      cli_number.length < 9 ||
      cli_number.length > 12 ||
      /\D/.test(cli_number)
    ) {
      err.cli_number = "Telefono non valido!";
    } */
    const docFilesErr = arrImages(documenti, err, f_error.doc_error);
    const fotoFilesErr = arrImages(fotoValutazione, err, f_error.foto_error);
    console.log(err);
    /* 
    //dimensione documenti
    arrImages(documenti, num, err);

    //dimensione fotoValutazione
    arrImages(fotoValutazione, num, err); */

    //appuntamento
    if (checkedPrenot) {
      const tempDay = appuntamento.sede.slice(0, 3).toLowerCase();
      if (appuntamento.data) {
        if (
          dataNum(appuntamento.data) < dataNum(currentDate()) ||
          selectedData(appuntamento.data) === "domenica"
        ) {
          err.data = "Data non valida!";
        }
        if (
          selectedData(appuntamento.data) === "sabato" &&
          tempDay !== "bed" &&
          tempDay !== "cap"
        ) {
          err.data = "Data non valida!";
        }
      }
      if (!formError.data && appuntamento.orario) {
        if (
          dataNum(appuntamento.data) <= dataNum(currentDate()) &&
          appuntamento.orario.slice(0, 2) <= currentHour()
        ) {
          err.orario = "Orario non valido!";
        }
      }
    }
    //ritiro veicolo
    if (!checkedPrenot) {
      let regEx = /([a-zA-Z0-9])\1{2,}/;
      if (ritiro.indirizzo.trim().length < 3) {
        err.indirizzo = "Indirizzo non valido";
      }
    }

    setFormError({ ...err });

    // Trova il nome del primo campo con un errore non vuoto
    const firstError = Object.keys(err).find((key) => err[key]);
    const firstErrorValue = firstError ? err[firstError] : undefined;

    return {
      isValid: Object.keys(err).length < 1,
      error: firstErrorValue,
      focus: firstError,
    };
  };

  return (
    <AppContext.Provider
      value={{
        form,
        formError,
        veicolo,
        catalog,
        checkedTarga,
        checkedPrenot,
        listOrario,
        /*  captchaRef, */
        privacy,
        marcaArr,
        modelArr,
        versionArr,
        yearArr,
        idMarca,
        idModel,
        idVersion,
        isLoading,
        setIsLoading,
        setCheckedTarga,
        setCheckedPrenot,
        setPrivacy,
        formUpdate,
        findTarga,
        handleSubmit,
        handleChange,
        handleRitiro,
        handleUpload,
        handleChangeSede,
        handleChangeOra,
        handleChangeData,
        autocompleateHandleChange,
        autocompleateHandlleApuntamento,
        validateForm,
        cancelData,
        setFormError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
