import AutoCompleteMMV from "../components/AutoCompleteMMV";
import { useGlobalContext } from "../context";

const MMV = () => {
  const { form, veicolo, marcaArr, modelArr, versionArr, yearArr } =
    useGlobalContext();

  const { marca, modello, versione, anno } = form;

  return (
    <div className="container-flex  f-col mmvVeicolo">
      <AutoCompleteMMV
        disabled={veicolo}
        type={"marca"}
        value={marca}
        arr={marcaArr[0] ? marcaArr.map((option) => option.MARCA) : []}
        label={"Marca"}
        loading={!marcaArr.length && !marca}
        idArr={marcaArr}
      />
      <AutoCompleteMMV
        disabled={veicolo || !marca}
        type={"modello"}
        value={modello}
        arr={modelArr[0] ? modelArr.map((option) => option.MODELLO) : []}
        label={"Modello"}
        loading={!modelArr.length && !modello} //veicolo ? false : marca ? !modelArr.length : false
        idArr={modelArr}
      />
      <AutoCompleteMMV
        disabled={veicolo || !modello}
        type={"versione"}
        value={versione}
        arr={versionArr[0] ? versionArr.map((option) => option.VERSIONE) : []}
        label={"Versione"}
        loading={!versionArr.length && !versione} //veicolo ? false : modello ? !versionArr.length : false
        idArr={versionArr}
      />
      <AutoCompleteMMV
        disabled={veicolo || !versione}
        type={"anno"}
        value={anno}
        arr={yearArr[0] ? yearArr : []}
        label={"Anno immatricolazione"}
        loading={false} //veicolo ? false : versione ? !yearArr.length : false //yearArr[0] && !annoImmatricolazione
      />
    </div>
  );
};

export default MMV;
