import React from "react";
import { useGlobalContext } from "../context";
import ApiServices from "../utility/api";

/* const LINK_PRIVACY =
  "https://www.autodemolizionepollini.it/it/informativa-trattamento-dati-personali-utenti";
 */

const apiServices = new ApiServices();
const Privacy = () => {
  const { setPrivacy, privacy } = useGlobalContext();

  return (
    <div className="container-flex alignCenter my-5 px-6 width100">
      <label className=" checkbox privacyLabel has-text-centered px-4">
        <input
          className="mr-1"
          type="checkbox"
          name="privacy"
          required
          checked={privacy}
          onChange={() => {
            setPrivacy(!privacy);
          }}
        />
        Dichiaro di aver dato lettura ed accettare la{" "}
        <a
          className="privacy"
          href={apiServices.urls.LINK_PRIVACY}
          target="blank"
        >
          Privacy Policy*
        </a>
      </label>
    </div>
  );
};

export default Privacy;
