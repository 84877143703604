export const appName = "APP Rottama il tuo veicolo";
export const mail_assistenza = "assistenzapratiche@pollinisrl.it";
export const tel_assistenza = "3406814547";
export const file_size = 5;
export const f_error = {
  foto_error: "foto errore",
  doc_error: "doc errore",
};

const elencoDocumentsBase = [
  {
    name: "docIdentita",
    description:
      "Documento di identità(Carta di identità, patente, passaporto)",
  },
  {
    name: "codiceFiscaleIntest",
    description: "Codice fiscale(Tessera verde, tessera sanitaria o CIE)",
  },
];

export const documentiNecessari = {
  autoveicolo: [
    ...elencoDocumentsBase,
    {
      name: "cartaCircolazione",
      description: "Carta di circolazione/Documento unico di circolazione",
    },
    {
      name: "certPropieta",
      description: "Certificato di proprietà/Documento unico di circolazione",
    },
  ],

  motoveicolo: [
    ...elencoDocumentsBase,
    {
      name: "cartaCircolazione",
      description: "Carta di circolazione/Documento unico di circolazione",
    },
    {
      name: "certPropieta",
      description: "Certificato di proprietà/Documento unico di circolazione",
    },
  ],
  rimorchio: [
    ...elencoDocumentsBase,
    {
      name: "cartaCircolazione",
      description: "Carta di circolazione/Documento unico di circolazione",
    },
    {
      name: "certPropieta",
      description: "Certificato di proprietà/Documento unico di circolazione",
    },
    {
      name: "cciaa",
      description: "Visura camerale(CCIAA) non più vecchia di un anno",
    },
  ],
  autocarro: [
    ...elencoDocumentsBase,
    {
      name: "cartaCircolazione",
      description: "Carta di circolazione/Documento unico di circolazione",
    },
    {
      name: "certPropieta",
      description: "Certificato di proprietà/Documento unico di circolazione",
    },
    {
      name: "cciaa",
      description: "Visura camerale(CCIAA) non più vecchia di un anno",
    },
  ],
  autobus: [
    ...elencoDocumentsBase,
    {
      name: "cartaCircolazione",
      description: "Carta di circolazione/Documento unico di circolazione",
    },
    {
      name: "certPropieta",
      description: "Certificato di proprietà/Documento unico di circolazione",
    },
    {
      name: "cciaa",
      description: "Visura camerale(CCIAA) non più vecchia di un anno",
    },
  ],
  altro: [
    ...elencoDocumentsBase,
    {
      name: "targetta",
      description: "Targhetta identificativa o altro codice di identificazione",
    },
  ],
};

export const tipiDelVeicolo = [
  "autoveicolo",
  "motoveicolo",
  "rimorchio",
  "autocarro",
  "autobus",
  "altro",
];

export const statoDelVeicolo = [
  "completo marciante",
  "completo NON marciante",
  "smontato",
  "bruciato",
];

export const fotoValoreDesc = [
  { name: "fotoAnteriore", description: "Foto anteriore" },
  { name: "fotoLatoSX", description: "Foto lato sx" },
  { name: "fotoLatoDX", description: "Foto lato dx" },
  { name: "fotoPosteriore", description: "Foto posteriore" },
  { name: "fotoInterni", description: "Foto interni" },
];

export const orari = [
  "08.00-09.00",
  "09.00-10.00",
  "10.00-11.00",
  "11.00-12.00",
  "14.00-15.00",
  "15.00-16.00",
  "16.00-17.00",
  "17.00-18.00",
];

export const elencoSede = [
  {
    localita: "Bedizzole (BS)",
    indirzzo: "Via Gavardina 30, 25081",
  },
  {
    localita: "Capriolo (BS)",
    indirzzo: "Viale Lombarda 92, 25031",
  },
  {
    localita: "S.Martino d/A (MN)",
    indirzzo: "Via Primo Maggio 14, 46010",
  },
  {
    localita: "Fornaci (BS)",
    indirzzo: "Via Del Mella 44/H/G, 25131",
  },
  {
    localita: "Brescia centro (BS)",
    indirzzo: "Via Borgosatollo 38, 25124",
  },
  {
    localita: "Busto Arsizio (VA)",
    indirzzo: "Strada Comunale di Arconate 31, 21052",
  },
  {
    localita: "Settimo Torinese (TO)",
    indirzzo: "Via Sicilia 2, 10036 ",
  },
];

export const DescrizioneStato = `<ul>
  <li><strong>Completo Marciante</strong>: Al veicolo non manca nulla, ovvero dispone di tutte le parti e accessori che aveva all'uscita dalla fabbrica. Il veicolo è in grado di spostarsi autonomamente.</li>
  <li><strong>Completo NON Marciante</strong>: Al veicolo non manca nulla, ovvero dispone di tutte le parti e accessori che aveva all'uscita dalla fabbrica. Il veicolo NON è in grado di spostarsi autonomamente.</li>
  <li><strong>Smontato</strong>: Al veicolo mancano una o più componenti rispetto a quelle con cui è uscito dalla fabbrica.</li>
  <li><strong>Bruciato</strong>:  Il veicolo ha subito danni da incendio, anche parziale o in piccole zone.</li>
</ul>`;

export const gomme = {
  completo: "Completo di gomme",
  non_completo: "Mancano una o più gomme",
};
