import React from "react";

const Button = ({
  type,
  disabled,
  title,
  description,
  class_name,
  handleClick = () => {},
}) => {
  return (
    <button
      className={`btn ${class_name} `} //"button   mb-6 mt-4"
      title={description}
      type={type}
      name={title}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

export default Button;
