import React from "react";
import { TextField } from "@mui/material";
import ErrorMessage from "../components/ErrorMessage";
import { useGlobalContext } from "../context";

const Contacts = () => {
  const { form, formError, handleChange } = useGlobalContext();
  const { cli_number, email } = form;

  return (
    <div className="container-center contacts">
      <div className="container-center contacts">
        <div className="container-center mx-1 f-col ">
          <TextField
            className="uniformInput inputRed"
            id="cli_number"
            label="Telefono"
            required
            size="small"
            name={"cli_number"}
            value={cli_number}
            onChange={handleChange}
            inputProps={{ minLength: 9, maxLength: 15, pattern: "[0-9]*" }}
          />
          <ErrorMessage
            message={form?.cli_number?.length > 0 ? formError.cli_number : null}
          />
        </div>
        <div className="container-center mx-3  f-col ">
          <TextField
            className="uniformInput inputRed"
            id="email "
            label="Email"
            size="small"
            name={"email"}
            value={email}
            onChange={handleChange}
            inputProps={{ type: "email", autoComplete: "mail" }}
          />
          <ErrorMessage message={formError.email} />
        </div>
      </div>
      <div className="paramIcon"></div>
    </div>
  );
};

export default Contacts;
