import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import AutoCompleteComponent from "./AutoCompleteComponent";
import { useGlobalContext } from "../context";
import { getCitiesOfRegion, getStatesOfCountry } from "../utility/geoFunction";

const codItalia = "3175395";
const codLombardia = "3174618";
const title = "LUOGO DEL RITIRO VEICOLO";
const avviso =
  "AVVISO:è possibile attribuzione di un costo per ritiri oltre una certa distanza dalla sede";

const RitiroComponent = () => {
  const { handleRitiro, form } = useGlobalContext();
  /* array di reggioni */
  const [regioni, setRegioni] = useState([]);
  /* array di province */
  const [province, setProvince] = useState([]);
  /* array di citta */
  const [leCitta, setLeCitta] = useState([]);

  const { regione, citta, provincia, indirizzo } = form.ritiro;

  /* TROVO LA REGIONE E PROVINCIA IN ARRAY */
  const regioneSelected = regioni.find((option) => option.name === regione);
  const provinciaSelected = province.find(
    (option) => option.name === provincia
  );

  /* CHIAMA REGIONI E PROVINCE di LOMBARDIA */
  useEffect(() => {
    getStatesOfCountry(codItalia, setRegioni);
    getStatesOfCountry(codLombardia, setProvince, setProvince, setLeCitta);
  }, []);

  /* CHIAMA PROVINCE SE CAMBIA REGIONE */
  useEffect(() => {
    if (regioneSelected) {
      getStatesOfCountry(regioneSelected.geonameId, setProvince);
    }
  }, [regione, regioneSelected]);

  /* CHIAMA CITTA IN BASE A PROVINCIA */
  useEffect(() => {
    if (provinciaSelected) {
      getCitiesOfRegion(provinciaSelected.geonameId, setLeCitta);
    }
  }, [provincia, provinciaSelected]);

  return (
    <div className="container-center f-col">
      <div className="  has-text-weight-semibold p-2">{title}</div>
      <div className="container-center f-col mb-2">
        <div className="container-center  ritiro ">
          <AutoCompleteComponent
            value={regione}
            destination={"regione"}
            paramState={regioni}
            infoDisabled={false}
            label={"Regione"}
            loading={!regioni.length}
          />
          <AutoCompleteComponent
            value={provincia}
            destination={"provincia"}
            paramState={province}
            infoDisabled={!regione}
            label={"Provincia"}
            loading={!province.length}
          />
        </div>

        <div className="container-center ritiro">
          <AutoCompleteComponent
            value={citta}
            destination={"citta"}
            paramState={leCitta}
            infoDisabled={!provincia}
            label={"Citta"}
            loading={provincia && !leCitta.length}
          />

          <div className="container-center f-col m-1">
            <TextField
              className="uniformInput inputRed"
              size="small"
              id="indirizzo"
              name={"indirizzo"}
              value={indirizzo}
              label="indirizzo"
              required
              onChange={handleRitiro}
            />
          </div>
        </div>
      </div>
      <div className="has-text-centered has-text-weight-semibold has-text-danger-dark  is-flex-wrap-wrap py-1 px-4">
        {avviso}
      </div>
    </div>
  );
};

export default RitiroComponent;
