import React from "react";
import { useGlobalContext } from "../context";

const stato_gomme = {
  compleate: "Ha tutte le gomme montate",
  not_compleate: "Mancano una o più gomme montate",
};

const InfoGomme = () => {
  const { handleChange } = useGlobalContext();

  return (
    <div
      className="container-flex f-col control mb-4 infoGomme"
      style={{ fontSize: "0.9rem" }}
    >
      <label className="radio radio-item">
        <input
          className="mr-1"
          type="radio"
          required
          name="gomme"
          value={true}
          onChange={handleChange}
        />
        {stato_gomme.compleate}
      </label>
      <label className="radio ml-0">
        <input
          className="mr-1"
          type="radio"
          name="gomme"
          value={false}
          onChange={handleChange}
        />
        {stato_gomme.not_compleate}
      </label>
    </div>
  );
};

export default InfoGomme;
