import ApiServices from "../api";

const apiServices = new ApiServices();

export async function recaptchaVerification(token) {
  const response = await apiServices.post(apiServices.urls.verify_token, {
    token,
  });
  if (response?.success && response?.score > 0.6) return true;
  else return false;
}
