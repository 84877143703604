import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useGlobalContext } from "../context";

const AutoCompleteComponent = ({
  value,
  destination,
  paramState,
  infoDisabled,
  label,
  loading,
}) => {
  const { autocompleateHandlleApuntamento } = useGlobalContext();

  return (
    <div className="container-center f-col m-1 ">
      <Autocomplete
        className=" inputRed uniformInput"
        /*  style={{ width: "16rem" }} */
        size="small"
        /*  sx={{ width: 300 }} */
        value={value ? value : null}
        disabled={infoDisabled}
        /*  id="controllable-states-demo" */
        options={paramState.map((option) => option.name)}
        loading={Boolean(loading)}
        onChange={(event) => {
          autocompleateHandlleApuntamento(event, destination);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            required
          />
        )}
      />
    </div>
  );
};

export default AutoCompleteComponent;
