import React from "react";

import DocumentComponent from "../components/DocumentComponent";
import {
  documentiNecessari,
  f_error,
  mail_assistenza,
  tel_assistenza,
} from "../utility/param";
import { useGlobalContext } from "../context";
import ErrorMessage from "../components/ErrorMessage";

const Documents = () => {
  const { form, formError } = useGlobalContext();
  const { documenti, tipo_veicolo } = form;

  return (
    <div className="container-center f-col mb-5 p-2">
      <div className="has-text-centered  has-text-weight-semibold is-size-5 is-flex-wrap-wrap px-4">
        Per procedere con l`inserimento della pratica ti servono:
      </div>

      <div className="container-center docScreen my-3">
        {/* crea elenco documeti necessarie in base a tipo del veicolo selezionato */}
        {documentiNecessari[tipo_veicolo ? tipo_veicolo : "autoveicolo"].map(
          (item, i) => (
            <DocumentComponent
              item={item}
              key={i}
              error={formError[item.name]}
              file={documenti[item.name]}
            />
          )
        )}
      </div>
      <ErrorMessage
        message={
          formError[f_error.doc_error] ? formError[f_error.doc_error] : null
        }
      />
      <div className="has-text-centered has-text-weight-semibold py-2 px-4">
        Se non siete in possesso anche solo di uno dei documenti sopra elencati
        telefonate o WhatsApp a{" "}
        <a href={`tel:${tel_assistenza}`}>{tel_assistenza}</a>, oppure scrivete
        una mail a <a href={`mailto:${mail_assistenza}`}>{mail_assistenza}</a>
      </div>
    </div>
  );
};

export default Documents;
