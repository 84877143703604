export default class EmailService {
  DESTINATION_EMAIL = "software3@pollinisrl.it"; //ced5@pollinisrl.it;
  SUBJECT = "Richiesta di rottamazione";

  formDataToSend(form, id) {
    let formDataToSend = new FormData();

    formDataToSend.append("email", this.DESTINATION_EMAIL);
    //cerco se in documenti e fotoValutazione ci sono file e estrago in formData
    this.findFile(form, ["documenti", "fotoValutazione"]).forEach((file) =>
      formDataToSend.append("files", file)
    );
    formDataToSend.append("subject", this.SUBJECT);
    formDataToSend.append(
      "html",
      this.transformHtml({ ...form, id_rottamazione: id })
    );

    return formDataToSend;
  }
  formDataFiles(form, id, date) {
    let formDataFiles = new FormData();

    formDataFiles.append("data_inserimento", date);
    formDataFiles.append("id_rottamazione", id);
    this.findFile(form, ["documenti", "fotoValutazione"]).forEach((file) =>
      formDataFiles.append("files", file)
    );
    return formDataFiles;
  }
  //crea html per la email
  transformHtml(form) {
    const title =
      "&Egrave; stata effettuata una nuova <strong>richiesta di rottamazione</strong>";
    const htmlForma = `<div><div style="margin: 1rem 1rem;"><p>${title}</p></br>${this.createList(
      form,
      "Dettagli veicolo"
    )}</ul></br>${
      form.ritiro.indirizzo
        ? this.createList(form.ritiro, "Dettagli ritiro")
        : this.createList(form.appuntamento, "Dettagli consegna")
    }</ul></div></div>`;
    return htmlForma;
  }

  // crea una lista di dati
  createList(obj, text) {
    const style = "list-style: none";
    let indirizzo = `<p style="font-weight: bold;">${text}</p></br><ul style="${style}">`;

    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value !== "object" && value) {
        indirizzo += `<li>${this.transformKey(
          key
        )}: <strong>${value}</strong></li>`;
      }
    });

    return indirizzo;
  }

  // trasforma chiavi del oggetto
  transformKey(word) {
    if (word === word.toUpperCase()) {
      return word.toLowerCase();
    }
    // Gestisce i casi specifici
    switch (word) {
      case "cli_number":
        return "Numero telefono";
      /*   case "cli_name":
        return "Nome"; */
      default:
        //  trasformare camelCase in frase
        return word
          .replaceAll("_", " ")
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());
    }
  }

  // trova tutti file in documenti e fotoValutazione e inserisce in una array
  findFile(obj, arr) {
    return arr.flatMap((key) =>
      Object.values(obj[key]).filter((value) => value)
    );
  }
}
