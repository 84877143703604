import React from "react";
import { TextField } from "@mui/material";
import SelectComponent from "../components/SelectComponent";
import ErrorMessage from "../components/ErrorMessage";
import { elencoSede } from "../utility/param";
import { useGlobalContext } from "../context";

const Appuntamento = () => {
  const {
    handleChangeOra,
    handleChangeSede,
    handleChangeData,
    listOrario,
    formError,
    form,
  } = useGlobalContext();
  const { sede, data, orario } = form.appuntamento;

  const errorMessage = () => {
    const err = formError.data
      ? formError.data
      : form.appuntamento.orario
      ? formError.orario
      : null;
    if (err) {
      return `Attenzione: ${err}`;
    }
  };
  return (
    <div className="container-center f-col mb-4">
      {/* PRENOTAZIONE APPUNTAMENTO */}
      <div className="p-2  has-text-weight-semibold">
        PRENOTA UN APPUNTAMENTO
      </div>
      <div className="container-center f-col">
        <div className="container-flex prenotazione">
          <SelectComponent
            name={"sede"}
            options={elencoSede.map((item) => item.localita)}
            handleChange={handleChangeSede}
            infoDisambled={false}
            formError={formError.sede}
            label={"Sede"}
            value={sede}
            styled={true}
          />
          <div className="container-flex m-1 f-col ">
            <TextField
              className="uniformInput inputRed"
              disabled
              size="small"
              id="outlined-disabled"
              value={
                sede && sede !== "Sede"
                  ? elencoSede.find((item) => item.localita === sede).indirzzo
                  : "Indirizzo"
              }
            />
          </div>
        </div>
        <div className="container-center f-col">
          <div className="container-flex prenotazione  ">
            <div className="container-flex  f-col ">
              <TextField
                disabled={!sede}
                className="uniformInput m-1 inputRed"
                required
                name="data"
                type="date"
                size="small"
                id="calendar"
                onChange={handleChangeData}
                value={data}
              />
              {/* {formError.data &&  <ErrorMessage message={formError.data} />} */}
            </div>
            <div className="container-center f-col">
              <SelectComponent
                name={"orario"}
                options={formError.data ? [] : listOrario}
                handleChange={handleChangeOra}
                infoDisambled={!data}
                formError={formError.orario}
                label={"Orario"}
                value={orario}
                styled={true}
              />
              {/*    {
              (formError.data || formError.orario) &&  <ErrorMessage
                message={form.appuntamento.orario ? formError.orario : null}
              />
            } */}
            </div>
          </div>
          {<ErrorMessage message={errorMessage()} />}
        </div>
      </div>
    </div>
  );
};

export default Appuntamento;
