import React from "react";

import { useGlobalContext } from "../context";
import FileIconResponse from "./FileIconResponse";

const FotoValoreComponent = ({
  name,
  description,
  file,
  destination,
  error,
}) => {
  const { handleUpload } = useGlobalContext();
  return (
    <div className="container-center f-col mx-4">
      <div
        className="file  is-boxed is-small m-2"
        title={file ? file.name : "Scegli imagine da caricare"}
      >
        <label className="file-label w-100">
          <input
            className="file-input"
            id={name}
            type="file"
            name={name}
            accept="image/*"
            onChange={(e) => handleUpload(destination, e)}
          />
          <span className="file-cta gap-4">
            <FileIconResponse
              error={error}
              file={file}
              class_name={"is-size-6 has-text-centered ml-2"}
            />

            <span className="file-label">
              {file
                ? file.name.substring(0, 10) +
                  (file.name.length > 10 ? "..." : "")
                : description}
            </span>
          </span>
        </label>
      </div>
    </div>
  );
};

export default FotoValoreComponent;
