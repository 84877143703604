import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useGlobalContext } from "../context";

const AutoCompleteMMV = ({
  loading,
  disabled,
  type,
  value,
  arr,
  label,
  idArr,
}) => {
  const { handleChange, autocompleateHandleChange } = useGlobalContext();
  const maxdate =
    type === "anno"
      ? {
          max: new Date().getFullYear(),
          //  type: "number",
          step: "1",
          min: "1900",
          pattern: "[0-9]{4}",
        }
      : {};

  return (
    <div className="container-flex  autocompleateContainer">
      <Autocomplete
        className="autocompleate inputRed borderColor"
        size={"small"}
        value={value}
        disabled={disabled}
        freeSolo
        /*  id="free-solo-2-demo" */
        disableClearable
        options={arr}
        loading={loading}
        onChange={(event) => autocompleateHandleChange(event, type, idArr)}
        renderInput={(params) => (
          <TextField
            name={type}
            value={value}
            required
            onChange={(event) => autocompleateHandleChange(event, type, idArr)}
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              inputProps: { ...params.inputProps, ...maxdate },
              type: "search",

              endAdornment: (
                <React.Fragment>
                  {loading && !disabled ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutoCompleteMMV;
