import { file_size, orari } from "./param";

/* data attuale */
export const currentDate = () => {
  const date = new Date().toJSON().slice(0, 10);

  return date;
};

/* inverte formato della data */
export const dataNum = (param) => {
  return param.split("-").join("");
};

/* ora attuale */
export const currentHour = () => {
  const hour = new Date().getHours();

  return hour;
};

/* data selezionata */
export const selectedData = (param) => {
  let date;
  if (param) {
    const [year, month, day] = param
      .split("-")
      .map((element) => Number(element));
    const event = new Date(Date.UTC(year, month - 1, day));
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    date = event.toLocaleDateString(undefined, options).split(" ")[0];
  }

  return date;
};

/* array di ore in base a sede e data selezionata */
export const elencoOra = (data, sede) => {
  let ore = [];
  const date = selectedData(data);
  const str = sede.slice(0, 3).toLowerCase();
  if (date === "sabato") {
    if (str === "bed" || str === "cap") {
      ore = orari.slice(0, 4);
    } else {
      return ore;
    }
  } else if (date === "domenica" || str === "sed") {
    return ore;
  } else {
    ore = orari;
  }

  return ore;
};

/* controllo dimensioni file */
export const arrImages = (obj, dest, tipo) => {
  let count = 0;
  const maxSizeInBytes = file_size * 1024 * 1024;
  if (obj && Object.keys(obj).length > 0) {
    const tempArr = Object.entries(obj);
    tempArr.forEach((item) => {
      if (item[1] && item[1].size > maxSizeInBytes) {
        count++;
        // Assegna il messaggio di errore all'oggetto di destinazione
        dest[item[0]] = "Dimensione non consentite!";
      }
    });
  }
  if (count > 0)
    dest[tipo] = `Dimensioni massime consentite per i file: ${file_size} MB`;
  return count;
};

/* cancela dati di ritiro o appuntamento  in base al check*/
export const updateConsegna = (prenotation, obj, dest) => {
  let updatedForm;
  if (prenotation) {
    const ritiro = {
      regione: "",
      indirizzo: "",
      citta: "",
      provincia: "",
      cap: "",
    };
    updatedForm = { ...obj, ritiro };
  } else {
    const appuntamento = {
      data: "",
      orario: "",
      sede: "",
    };
    updatedForm = { ...obj, appuntamento };
  }
  dest(updatedForm);
};

/* crea array di anni in base a inizio comercio e fine comercio */
export const arrYears = (start, finish) => {
  let yearsArray = [];
  let startYear = Number(start?.slice(0, 4));
  let finishYear = Number(finish?.slice(0, 4));
  if (startYear > 1900 && startYear <= finishYear) {
    for (let i = startYear; i <= finishYear; i++) {
      yearsArray.push(i.toString());
    }
  } else if (startYear > 1900 && !finishYear) {
    const currentYear = new Date().getFullYear();
    for (let i = startYear; i <= currentYear; i++) {
      yearsArray.push(i.toString());
    }
  } else {
    yearsArray.push(0);
  }
  return yearsArray;
};

/* trova tutti file in documenti e fotoValutazione e inserisce in una array 
export const findFile = (obj, arr) => {
  let filesArray = [];
  for (let i = 0; i < arr.length; i++) {
    for (let key in obj[arr[i]]) {
      if (obj[arr[i]][key]) {
        filesArray.push(obj[arr[i]][key]);
      }
    }
  }
  console.log(filesArray);
  return filesArray;
};*/

export function trasformaChiaviInMinuscolo(oggetto) {
  return Object.fromEntries(
    Object.keys(oggetto).map((chiave) => [
      chiave.toLowerCase(),
      oggetto[chiave],
    ])
  );
}
export const dataOra = () => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const time = new Date().toLocaleString(undefined, options).replace(",", "");

  return time;
};
