const rottamamiUrl = "https://rottamami.polliniapp.it/rest/service/";
const serviceUrl = "https://visure.polliniapp.it"; // "https://185.149.190.207";
const emailService = serviceUrl + ":9100";
const rottamamiService = serviceUrl + ":9099";
const polliniUrl = "https://www.autodemolizionepollini.it/";
export default class ApiServices {
  urls = {
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify?secret=",

    LINK_PRIVACY:
      polliniUrl + "it/informativa-trattamento-dati-personali-utenti",
    search_targa: polliniUrl + "api/v1/ext/license_plate/",
    verify_token: rottamamiService + "/verify-token",
    //!PRODUCTION
    send_email: emailService + "/send",
    log_modifiche_veicolo: rottamamiService + "/log-veicolo",
    rottamazione_veicoli: rottamamiService + "/rott-veicolo",
    upload_files: rottamamiService + "/upload",
    API_MARCA: rottamamiUrl + "v-marche/endpoint.php",
    API_MODELLO: rottamamiUrl + "v-modelli/endpoint.php?IDMAR=",
    API_VERSIONE: rottamamiUrl + "v-versioni/endpoint.php?IDMAR=",
    API_CATALOGO_AUTO: rottamamiUrl + "catalogo_auto/endpoint.php?IDMAR=",

    //!TEST
    /*   send_email: "http://localhost:9100/send",
    upload_files: "http://localhost:9099/upload",
    API_MARCA: "http://localhost:5555/marche",
    API_MODELLO: "http://localhost:5555/modelli?IDMAR=",
    API_VERSIONE: "http://localhost:5555/versioni?IDMAR=",
    API_CATALOGO_AUTO: "http://localhost:5555/catalogo-auto?IDMAR=",
    rottamazione_veicoli: "http://localhost:9099/rott-veicolo",
    log_modifiche_veicolo: "http://localhost:9099/log-veicolo", */
  };
  license_api_key = "U9zaUR7dmwUqhNkM";
  //? GET
  async get(url) {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error("response statusCode : " + response.status);
      const data = await response.json();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
  //? GET
  async getModificata(url) {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error("response statusCode : " + response.status);
      const data = await response.json();
      return this.succ(data);
    } catch (err) {
      console.error(err);
      return this.err(err);
    }
  }

  //? POST
  async post(url, data) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data), //carico i dati in formato json
      });

      if (!response.ok)
        throw new Error("response statusCode : " + response.status);

      return await response.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  }
  //? POST
  async postFormData(url, data) {
    try {
      const response = await fetch(url, {
        method: "POST",

        body: data, //carico i dati in formato json
      });

      if (!response.ok)
        throw new Error("response statusCode : " + response.status);

      return await response.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  err = (err) => {
    return {
      success: false,
      data: [],
      err: err,
    };
  };
  succ = (res) => {
    return {
      success: true,
      data: res,
    };
  };
}
