import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <span
      className="has-text-danger is-size-7 mb-1 has-text-centered "
      style={{ height: "1rem" }}
    >
      {message}
    </span>
  );
};

export default ErrorMessage;
