import dayjs from "dayjs";
import { alertsObj } from "../SweetAlert";
import ApiServices from "../api";
import { appName, gomme } from "../param";
import { dataOra } from "../utilityFunction";

export default class RichiestaRottamazione {
  #Api = new ApiServices();
  stato_richiesta = "Richiesta informazioni";

  async vehicleDataManagement(obj, consegna, date) {
    // se targa e vuota fa la post del veicolo
    if (!obj.targa) {
      const res = await this.postVehicle(obj, consegna, date);
      if (!res.success) {
        return this.#Api.err(alertsObj.rot_falita); //alertsObj.rot_falita;
      }
      await this.log_modifiche_veicoli(appName, {}, obj, res.data.lastInsertId);

      return res;
    }

    // se ce targa, controlo prima se e presente veicolo
    const findVehicle = await this.#Api.get(
      `${this.#Api.urls.rottamazione_veicoli}?targa=${obj.targa}`
    );
    if (!findVehicle?.success) {
      return this.#Api.err(alertsObj.rot_falita);
    }
    if (findVehicle.success && findVehicle.data.length > 0) {
      return this.#Api.err(alertsObj.veicolo_presente);
    }
    const res = await this.postVehicle(obj, consegna, date);
    if (!res.success) {
      return this.#Api.err(alertsObj.rot_falita);
    }
    await this.log_modifiche_veicoli(appName, {}, obj, res.data.lastInsertId);

    return res;
  }

  async postVehicle(obj, consegna, date) {
    const data = { ...obj };
    data.data_inserimento = date;
    data.stato_pratica = this.stato_richiesta;
    data.ritiro_v = consegna ? 0 : 1;
    data.gomme = obj.gomme === "true" ? gomme.completo : gomme.non_completo;
    data.indirizzo = this.trasformObjToString(
      obj[consegna ? "appuntamento" : "ritiro"]
    );
    // se con cerca per targa e stato trovato veicolo lo salvo come stringa
    data.veicolo = obj.veicolo ? JSON.stringify(obj.veicolo) : null;
    delete data.appuntamento;
    delete data.ritiro;
    delete data.documenti;
    delete data.fotoValutazione;

    const response = await this.#Api.post(
      this.#Api.urls.rottamazione_veicoli,
      data
    );
    if (response?.success) {
      return response;
    } else return this.#Api.err("Errore durante il salvataggio dei dati");
  }

  //? salvare log della modifica veicolo
  async log_modifiche_veicoli(operatore, originalD, modifiedD, id) {
    const originalToString = JSON.stringify(originalD);
    const updateToString = JSON.stringify(modifiedD);

    const data = {
      nome_operatore: operatore,
      id_rottamazione: id,
      data_modifica: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      dati_originali: originalToString,
      dati_aggiornati: updateToString,
    };

    const response = await this.#Api.post(
      this.#Api.urls.log_modifiche_veicolo,
      data
    );
    if (response?.affected_rows > 0) {
      return this.#Api.succ(response);
    } else return this.#Api.err("Log veicolo non salvato");
  }

  trasformObjToString = (param) => {
    return Object.entries(param)
      .map(([chiave, valore]) => `${chiave}: ${valore}`)
      .join("||");
  };
}
