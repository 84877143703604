import SearchTarga from "./components/SearchTarga";
import MMV from "./AppParts/MMV";
import StatoTipo from "./AppParts/StatoTipo";
import InfoGomme from "./components/InfoGomme";
import Contacts from "./AppParts/Contacts";
import Documents from "./AppParts/Documents";
import ValoreAuto from "./AppParts/ValoreAuto";
import Ritiro from "./AppParts/Ritiro";
import Appuntamento from "./AppParts/Appuntamento";
import Privacy from "./components/Privacy";
import { FaInfoCircle } from "react-icons/fa";
import { DescrizioneStato } from "./utility/param";
import { exampleAlert } from "./utility/SweetAlert";
import { useGlobalContext } from "./context";
import CheckConsegna from "./components/CheckConsegna";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Button from "./components/Button";

function App() {
  const { form, checkedPrenot, handleSubmit, privacy, isLoading, cancelData } =
    useGlobalContext();
  const { stato_veicolo } = form;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{ async: true }}
    >
      <div className="container-center f-col has-background-light ">
        <div className="imageContainer">
          <div className="container-start m-3">
            <img
              className="logo-pollini"
              src="./img/logo-pollini-autodemolizioni.png"
              alt="logo-Pollini"
              width={200}
              height={200}
              //         style={{ backgroundColor: "#fff" }}
            />
          </div>
        </div>
        <div className="container-center container_app">
          <div className="container-center f-col has-background-white containerRottama ">
            <div className="title has-text-centered  spak-title is-size-1 has-text-grey-dark is-uppercase">
              Rottama il tuo veicolo
            </div>
            <form
              className="container-center f-col px-2"
              onSubmit={handleSubmit}
            >
              <div className="container-center f-col  mb-5 ">
                <div className="container-center f-col  ">
                  <div className="container-flex infoAlert">
                    <div className="container-flex f-col">
                      <div className="container-center f-col mb-2">
                        <SearchTarga />
                        <MMV />
                      </div>
                      <StatoTipo />
                    </div>
                    <div className="container-end mb-2">
                      {/* DESCRIZIONE STATO */}
                      <div className="container-center f-col">
                        <FaInfoCircle
                          className="faInfoCircle icon iconStato"
                          onClick={() =>
                            exampleAlert(
                              "Info stato",
                              "",
                              "info",
                              DescrizioneStato
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {stato_veicolo !== "completo marciante" && stato_veicolo ? (
                    <InfoGomme />
                  ) : null}
                  <Contacts />
                </div>
              </div>
              <Documents />
              <ValoreAuto />
              {!checkedPrenot ? (
                <Ritiro />
              ) : stato_veicolo === "bruciato" ||
                stato_veicolo === "completo NON marciante" ? (
                <Ritiro />
              ) : (
                <Appuntamento />
              )}
              {stato_veicolo === "bruciato" ||
              stato_veicolo === "completo NON marciante" ? null : (
                <div className="container-center">
                  <CheckConsegna />
                </div>
              )}
              <Privacy />
              <div className="container-end w-100 gap-10 containerBtn px-6">
                <Button
                  class_name={"mb-6 mt-4"}
                  type="button"
                  disabled={isLoading}
                  title="Cancella dati"
                  description={"Resetta il modulo di rottamazione"}
                  handleClick={cancelData}
                />
                <Button
                  class_name={"mb-6 mt-4 "}
                  type="submit"
                  disabled={!privacy || isLoading}
                  title="Invia richiesta"
                  description={"Invia la richiesta di rottamazione"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
