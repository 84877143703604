import React from "react";

import { FaInfoCircle } from "react-icons/fa";
import { exampleAlert } from "../utility/SweetAlert";
import { useGlobalContext } from "../context";
import FileIconResponse from "./FileIconResponse";

const DocumentComponent = ({ item, file, error }) => {
  const { handleUpload } = useGlobalContext();
  const { name, description } = item;
  return (
    <div className="container-center f-col m-2  document">
      <div className="container-center f-col ">
        <div
          className="file is-medium  is-boxed mb-1"
          title={file ? file.name : "Scegli file da caricare"}
        >
          <label className="file-label w-100">
            <input
              className="file-input"
              id={name}
              type="file"
              name={name}
              accept=".pdf, image/*"
              onChange={(event) => handleUpload("documenti", event)}
            />
            <span className="file-cta gap-4 py-2">
              <FileIconResponse
                error={error}
                file={file}
                style={{ marginRight: "0px" }}
                class_name={"is-size-6"}
              />

              <span className="is-size-7">
                {file
                  ? file.name.substring(0, 10) +
                    (file.name.length > 10 ? "..." : "")
                  : "Scegli file"}
              </span>
            </span>
          </label>
        </div>
        <FaInfoCircle
          className="faInfoCircle icon "
          onClick={() =>
            exampleAlert(
              "Esempio",
              `${description}`,
              "",
              "",
              `/img/${name}.jpg`,
              200,
              `${description}`
            )
          }
        />
      </div>
      <div className="container-center has-text-centered mb-3 is-flex-wrap-wrap p-1">
        <div className="textDescription">-{description}</div>
      </div>
    </div>
  );
};

export default DocumentComponent;
