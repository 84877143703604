import { appName } from "./param";

export const formObject = {
  targa: "",
  marca: "",
  modello: "",
  versione: "",
  cod_motore: "",
  anno: "",
  tipo_veicolo: "autoveicolo",
  stato_veicolo: "",
  gomme: "",
  email: "",
  IDMAR: "",
  IDMOD: "",
  IDVER: "",
  valut_owner: "",
  valut_business: "",
  telaio: "",
  commerciale: "",
  cli_number: "",

  appuntamento: {
    sede: "",
    data: "",
    orario: "",
  },
  ritiro: {
    regione: "Lombardia",
    provincia: "",
    cap: "",
    citta: "",
    indirizzo: "",
  },
  //? agiunte per segnalare che da App
  cli_name: "",
  nome_operatore: appName,
  stato_pratica: "Richiesta informazioni",
  // no rot_veicolo
  documenti: {
    cartaCircolazione: null,
    certPropieta: null,
    docIdentita: null,
    codiceFiscaleIntest: null,
    cciaa: null,
    targetta: null,
  },
  fotoValutazione: {
    fotoAnteriore: null,
    fotoLatoSX: null,
    fotoLatoDX: null,
    fotoPosteriore: null,
    fotoInterni: null,
  },
};
