import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { colors } from "./variabiles/colors";

const MySwal = withReactContent(Swal);

export const exampleAlert = (
  title,
  message,
  type,
  html,
  imageUrl,
  imageWidth,
  imageAlt,
  cancelBtn,
  confirmBtnText = "Chiudi"
) => {
  return MySwal.fire({
    title: title,
    text: message,
    icon: type,
    html: html,
    imageUrl: imageUrl,
    imageWidth: imageWidth,
    imageAlt: imageAlt,
    showCancelButton: cancelBtn,
    confirmButtonColor: colors.green,
    cancelButtonColor: colors.red,
    cancelButtonText: "No",
    confirmButtonText: confirmBtnText,
  });
};

export const simpleAlert = (options) => {
  return MySwal.fire({
    ...options,
    confirmButtonText: "Ok",
    confirmButtonColor: colors.green,
  });
};
export const alertsObj = {
  rot_inviata: {
    title: "Richiesta inviata",
    text: "La tua richiesta è stata inviata. Verrai contattato da uno dei nostri operatori al più presto",
    icon: "success",
  },
  recapcha_error: {
    title: "Attenzione",
    text: "Non hai superato la verifica, riprova più tardi",
    icon: "error",
  },
  rot_falita: {
    title: "Richiesta non inviata",
    text: "Non è stato possibile inviare la richiesta. Riprova più tardi",
    icon: "warning",
  },
  dati_cancelati: {
    title: "Avviso!",
    text: "Tutti dati sono stati eliminati!",
    icon: "success",
  },
  veicolo_presente: {
    title: "Veicolo presente",
    text: "La richiesta per questa targa è già stata inoltrata in precedenza",
    icon: "warning",
  },
  validation_dati: {
    title: "Attenzione!",
    text: "Controlare Dati!",
    icon: "warning",
  },
};
