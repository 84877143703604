import React from "react";
import FotoValoreComponent from "../components/FotoValoreComponent";
import { f_error, fotoValoreDesc } from "../utility/param";
import { useGlobalContext } from "../context";
import ErrorMessage from "../components/ErrorMessage";

const ValoreAuto = () => {
  const { form, formError } = useGlobalContext();
  const valore = "$$$";

  return (
    <div className="container-center f-col my-4 p-2">
      <div className="is-italic has-text-weight-semibold">
        Il tuo veicolo puo valere <span className="valore">{valore}</span>
      </div>
      <p className="p-1">Per richiedere una valutazione invia foto</p>

      <div className="container-center screen">
        {/* creazione di 5 input tipo file per le foto del veicolo per valutazione */}
        {fotoValoreDesc.map((item, i) => {
          return (
            <FotoValoreComponent
              name={item.name}
              description={item.description}
              key={i}
              destination={"fotoValutazione"}
              error={formError[item.name]}
              file={form.fotoValutazione[item.name]}
            />
          );
        })}
      </div>
      <ErrorMessage
        message={
          formError[f_error.foto_error] ? formError[f_error.foto_error] : null
        }
      />
    </div>
  );
};

export default ValoreAuto;
