import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { colors } from "../utility/variabiles/colors";

export default function FileIconResponse({ error, file, class_name, style }) {
  return (
    <span className={`file-icon ${class_name}`} style={style}>
      {error ? (
        <FontAwesomeIcon
          icon={faCircleXmark}
          size="xl"
          style={{
            color: colors.red,
          }}
        />
      ) : file ? (
        <FontAwesomeIcon
          className="circleCheck"
          icon={faCircleCheck}
          size="xl"
          style={{
            color: colors.green,
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faCirclePlus}
          size="xl"
          style={{
            color: colors.gray,
          }}
        />
      )}
    </span>
  );
}
